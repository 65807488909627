import React from 'react'

export const AppleSvg = ({
  size = 60,
  className,
}: {
  size?: number
  className?: string
}) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 60 60"
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="#000"
        stroke="#000"
        strokeWidth="2"
        d="M29.998 1C21.991 1 14.741 4.246 9.494 9.494 4.246 14.742 1 21.992 1 30c0 8.009 3.246 15.259 8.493 20.506C14.74 55.754 21.99 59 29.998 59c8.01 0 15.26-3.245 20.508-8.493C55.754 45.259 59 38.009 59 30c0-8.008-3.246-15.258-8.495-20.506C45.257 4.246 38.007 1 29.998 1z"
      />
      <path
        fill="#FFF"
        d="M30.321 17.384c1.8-2.372 4.302-2.384 4.302-2.384s.373 2.23-1.415 4.379c-1.91 2.294-4.08 1.918-4.08 1.918s-.407-1.804 1.193-3.913M29.357 22.86c.926 0 2.645-1.273 4.882-1.273 3.85 0 5.364 2.74 5.364 2.74s-2.962 1.514-2.962 5.19c0 4.146 3.69 5.574 3.69 5.574s-2.58 7.262-6.064 7.262c-1.6 0-2.844-1.079-4.53-1.079-1.72 0-3.425 1.119-4.535 1.119-3.182 0-7.202-6.888-7.202-12.425 0-5.447 3.403-8.305 6.594-8.305 2.075 0 3.685 1.197 4.763 1.197"
      />
    </g>
  </svg>
)
