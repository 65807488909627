import { VendorSocialList } from 'common-constants/vendorSocialList'

export const VENDORS_SOCIAL_LIST_ICONS: Partial<Record<
  VendorSocialList,
  string
>> = {
  hyves: require('./icons/hyves.svg'),
  instagram: require('./icons/instagram.svg'),
  msn: require('./icons/msn.svg'),
  myspace: require('./icons/myspace.svg'),
  naszaklasa: require('./icons/nk.svg'),
  qq: require('./icons/qq.svg'),
  twitter: require('./icons/twitter.svg'),
  wechat: require('./icons/we-chat.svg'),
  weibo: require('./icons/weibo.svg'),
  yahoo: require('./icons/yahoo.svg'),
  google: require('./icons/google.svg'),
}
