import React, { FC } from 'react'
import { WithDataName } from 'common/types'

interface WhatsAppSvgProps {
  size?: number
}

export const WhatsAppSvg: FC<WhatsAppSvgProps & WithDataName> = ({
  size = 60,
  ...rest
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 60 60"
    data-name={rest['data-name']}
  >
    <g fill="none" fillRule="evenodd">
      <circle cx="30" cy="30" r="30" fill="#00E676" fillRule="nonzero" />
      <path
        d="M38.044 33.406c-.445-.221-2.63-1.291-3.037-1.439-.407-.148-.703-.221-1 .221-.296.443-1.148 1.44-1.407 1.735-.26.295-.518.332-.963.11-.444-.221-1.876-.688-3.574-2.195-1.32-1.172-2.213-2.62-2.472-3.064-.26-.443-.027-.682.195-.902.2-.198.444-.517.667-.775.222-.258.296-.443.444-.738.148-.295.074-.554-.037-.775-.111-.221-1-2.398-1.37-3.284-.36-.862-.727-.746-1-.76-.259-.012-.556-.015-.852-.015-.296 0-.778.111-1.185.554-.407.443-1.555 1.513-1.555 3.69 0 2.176 1.592 4.28 1.814 4.575.223.295 3.134 4.762 7.592 6.678 1.06.456 1.888.728 2.534.932 1.064.336 2.033.289 2.799.175.853-.127 2.629-1.07 3-2.103.37-1.033.37-1.919.258-2.103-.11-.185-.407-.295-.851-.517m-8.109 11.019h-.006c-2.653-.001-5.254-.71-7.524-2.051l-.54-.32-5.595 1.462 1.494-5.43-.352-.556c-1.48-2.342-2.261-5.05-2.26-7.83.003-8.11 6.635-14.71 14.789-14.71 3.948.001 7.66 1.534 10.451 4.314 2.791 2.78 4.327 6.477 4.326 10.408-.003 8.113-6.635 14.713-14.783 14.713M42.516 17.19c-3.358-3.345-7.823-5.188-12.581-5.19-9.803 0-17.782 7.94-17.786 17.7-.001 3.12.818 6.164 2.374 8.848L12 47.721l9.428-2.462c2.598 1.41 5.523 2.154 8.5 2.155h.007c9.802 0 17.782-7.94 17.786-17.7.002-4.73-1.846-9.177-5.205-12.523"
        fill="#FFF"
      />
    </g>
  </svg>
)
