export enum VendorSocialList {
  facebook = 'facebook',
  apple = 'apple',
  yandex = 'yandex',
  vkontakte = 'vkontakte',
  vkConnect = 'vkConnect',
  vkId = 'VK ID', // https://redmine.mamba.ru/issues/116186. Слишком много менять.
  vkIdFromServer = 'vkId', // TODO: либо удалить, либо поменять когда сервер поменяет название
  mailRu = 'mailru_oauth2',
  google = 'google',
  odnoklassniki = 'odnoklassniki',
  test = 'test',
  mailru = 'mailru',
  mailru_oauth2 = 'mailru_oauth2',
  telegram = 'telegram',
  /** Используется */
  instagramm = 'instagramm',
  whatsapp = 'whatsapp',
  viber = 'viber',
  hyves = 'hyves',
  instagram = 'instagram',
  msn = 'msn',
  myspace = 'myspace',
  naszaklasa = 'naszaklasa',
  qq = 'qq',
  twitter = 'twitter',
  wechat = 'wechat',
  weibo = 'weibo',
  yahoo = 'yahoo',

  /** @deprecated потому что вроде не используются */
  deprecatedViber = 'Viber',
  deprecatedFacebook = 'Facebook',
  deprecatedVK = 'Vkontakte',
  deprecatedMail = 'Mail.Ru',
  deprecatedGoogle = 'Google',
  deprecatedYandex = 'Yandex',
  deprecatedOdnoklassniki = 'Odnoklassniki',
  deprecatedMsn = 'Msn',
  deprecatedNaszaklasa = 'Naszaklasa',
  deprecatedYahoo = 'Yahoo',
}

export enum VendorUserList {
  camera = 'camera',
  gallery = 'gallery',
}
