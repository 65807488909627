import React, { FC } from 'react'
import { SocialSvgProps } from 'components/presentational/svg/Svg.constants'
import { WithDataName } from 'common/types'

export const OkSvg: FC<SocialSvgProps & WithDataName> = ({
  size = 60,
  className,
  fillBackgroundColor = '#EF8308',
  fillColor = 'white',
  ...rest
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    width={size}
    height={size}
    viewBox="0 0 60 60"
    data-name={rest['data-name']}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M60 30C60 46.5707 46.5689 60 29.9981 60C13.4311 60 0 46.5707 0 30C0 13.433 13.4311 0 29.9981 0C46.5689 0 60 13.433 60 30Z"
      fill={fillBackgroundColor}
    />
    <path
      d="M33.5709 37.4801C35.3686 37.0848 37.0996 36.3973 38.6926 35.4305C39.8985 34.6958 40.2634 33.1576 39.5025 31.9888C38.7415 30.8201 37.1486 30.4678 35.9382 31.2025C32.325 33.3982 27.675 33.3939 24.0617 31.2025C22.8514 30.4678 21.2584 30.8201 20.4975 31.9888C19.7365 33.1533 20.1014 34.6958 21.3073 35.4305C22.9003 36.3973 24.6313 37.0848 26.429 37.4801L21.5031 42.2409C20.4931 43.2162 20.4931 44.7931 21.5031 45.7685C22.0104 46.254 22.6689 46.4989 23.3275 46.4989C23.9905 46.4989 24.6491 46.254 25.1564 45.7685L30.0022 41.0893L34.848 45.7685C35.8581 46.7438 37.4912 46.7438 38.5013 45.7685C39.5114 44.7931 39.5114 43.2119 38.5013 42.2409L33.5709 37.4801ZM30.0022 18.4928C32.018 18.4928 33.6555 20.074 33.6555 22.0204C33.6555 23.9625 32.018 25.5437 30.0022 25.5437C27.9909 25.5437 26.3489 23.9625 26.3489 22.0204C26.3489 20.074 27.9909 18.4928 30.0022 18.4928ZM30.0022 30.5323C34.8658 30.5323 38.8216 26.7125 38.8216 22.0204C38.8216 17.3198 34.8658 13.5 30.0022 13.5C25.1386 13.5 21.1828 17.3198 21.1828 22.0161C21.1828 26.7125 25.1386 30.5323 30.0022 30.5323Z"
      fill={fillColor}
    />
  </svg>
)
