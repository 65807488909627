import { SocialSvgProps } from 'components/presentational/svg/Svg.constants'
import React, { FC } from 'react'
import { WithDataName } from 'common/types'

export const VkSvg: FC<SocialSvgProps & WithDataName> = ({
  className,
  size = 60,
  ...rest
}) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-name={rest['data-name']}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.9981 60C13.4311 60 0 46.5707 0 30C0 13.433 13.4311 0 29.9981 0C46.5689 0 60 13.433 60 30C60 46.5707 46.5689 60 29.9981 60Z"
      fill="#0077FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.2494 16.2494C14 18.4988 14 22.1192 14 29.36V30.64C14 37.8808 14 41.5012 16.2494 43.7506C18.4988 46 22.1192 46 29.36 46H30.64C37.8808 46 41.5012 46 43.7506 43.7506C46 41.5012 46 37.8808 46 30.64V29.36C46 22.1192 46 18.4988 43.7506 16.2494C41.5012 14 37.8808 14 30.64 14H29.36C22.1192 14 18.4988 14 16.2494 16.2494ZM19.5273 24.4C19.6989 32.724 24.0793 37.7236 31.2993 37.7236H31.7181V32.9818C34.3476 33.2482 36.3086 35.1902 37.1091 37.7236H40.9003C39.8718 33.9337 37.2051 31.8332 35.5477 31.0327C37.2038 30.0427 39.5477 27.6571 40.0998 24.4H36.6505C35.9269 27.0487 33.7738 29.452 31.7168 29.68V24.4H28.2125V33.6364C26.0799 33.1035 23.298 30.5146 23.184 24.4H19.5273Z"
      fill="white"
    />
  </svg>
)
