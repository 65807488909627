import React, { FC } from 'react'

interface TelegramAppSvgProps {
  size?: number
}

export const TelegramAppSvg: FC<TelegramAppSvgProps> = ({ size = 60 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 60 60"
  >
    <g fillRule="nonzero" fill="none">
      <circle cx="29.657" cy="29.657" r="29.657" fill="#4EA4F6" />
      <path
        d="M12.343 29.4s14.829-6.086 19.973-8.227c1.97-.86 8.656-3.601 8.656-3.601s3.085-1.2 2.828 1.714c-.085 1.2-.771 5.4-1.457 9.943-1.028 6.428-2.143 13.457-2.143 13.457s-.171 1.971-1.628 2.314c-1.457.343-3.857-1.2-4.286-1.543-.343-.257-6.429-4.114-8.657-6-.6-.514-1.286-1.543.086-2.743 3.085-2.828 6.771-6.342 9-8.571 1.028-1.029 2.057-3.429-2.229-.514-6.086 4.2-12.086 8.143-12.086 8.143s-1.371.857-3.943.085c-2.571-.771-5.571-1.8-5.571-1.8s-2.057-1.285 1.457-2.657z"
        fill="#FFF"
      />
    </g>
  </svg>
)
