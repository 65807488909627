import React, { FC } from 'react'

export interface FacebookSvgProps {
  size?: number
  className?: string
  fillBackground?: string
  fillColor?: string
}

export const FacebookSvg: FC<FacebookSvgProps> = ({
  size = 60,
  className,
  fillBackground = '#1877F2',
  fillColor = 'white',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    width={size}
    height={size}
    viewBox="0 0 60 60"
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill={fillBackground}
        d="M60 30C60 13.431 46.569 0 30 0 13.431 0 0 13.431 0 30c0 14.974 10.97 27.385 25.313 29.635V38.672h-7.618V30h7.617v-6.61c0-7.518 4.48-11.671 11.332-11.671 3.282 0 6.715.586 6.715.586v7.383h-3.783c-3.726 0-4.889 2.312-4.889 4.684V30h8.32l-1.33 8.672h-6.99v20.963C49.03 57.385 60 44.974 60 30"
      />
      <path
        fill={fillColor}
        d="M41.678 38.672L43.008 30h-8.32v-5.628c0-2.372 1.162-4.684 4.888-4.684h3.783v-7.383s-3.433-.586-6.715-.586c-6.853 0-11.332 4.153-11.332 11.672V30h-7.617v8.672h7.617v20.963a30.218 30.218 0 009.375 0V38.672h6.99z"
      />
    </g>
  </svg>
)
