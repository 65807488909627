import React, { FC } from 'react'

export const YahooSvg: FC<{ size?: number; className?: string }> = ({
  size = 60,
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 60 60"
    className={className}
  >
    <g fill="none" fillRule="evenodd">
      <path
        stroke="#7B0099"
        strokeWidth="2"
        d="M58.884 29.942C58.884 13.958 45.926 1 29.94 1 13.958 1 1 13.959 1 29.942c0 15.986 12.957 28.942 28.94 28.942 15.987 0 28.944-12.956 28.944-28.942z"
      />
      <path
        fill="#7B0099"
        d="M46.977 24.207c-.6.062-3.1.637-3.94.821-.899.247-9.116 6.77-9.657 8.372-.12.555-.18 1.408-.18 2.21l-.06 1.294c0 .925.25 2.413.37 3.214.54.124 4.451.017 5.17.14l-.09 1.656c-.7-.052-5.667-.04-8.504-.04-1.44 0-6.068.163-7.49.118l.268-1.576c.78-.06 4.01.145 4.719-.626.352-.381.24-.791.24-3.009v-1.048c0-.494 0-1.418-.12-2.28-.3-.926-7.539-10.212-9.398-11.691-.539-.186-3.923-.533-4.762-.718l-.043-1.42c.419-.215 4.182.053 7.833-.086 2.4-.091 7.873 0 8.55.083l-.173 1.25c-.72.186-4.188.254-5.087.5 2.34 3.575 6.039 8.178 7.24 9.966.658-.987 6.457-5.087 6.637-6.504-.9-.184-3.88-.626-4.36-.626l-.284-1.614c.816-.131 5.11 0 7.245 0 1.843 0 5.78 0 6.899.094l-1.023 1.52"
      />
    </g>
  </svg>
)
