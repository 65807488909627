import React, { FC } from 'react'

interface GooglePlusSvgProps {
  size?: number
  className?: string
}

export const GooglePlusSvg: FC<GooglePlusSvgProps> = ({
  size = 60,
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    width={size}
    height={size}
    viewBox="0 0 60 60"
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="#EEF4F8"
        d="M29.94 59.884C13.405 59.884 0 46.481 0 29.942 0 13.407 13.405 0 29.94 0 46.48 0 59.884 13.407 59.884 29.942c0 16.539-13.405 29.942-29.944 29.942z"
      />
      <path
        fill="#FF4131"
        d="M20.444 26.83c1.317-3.98 5.041-6.854 9.437-6.854 2.355 0 4.515.828 6.22 2.205l4.897-4.965C38.048 14.52 34.255 13 29.881 13c-6.63 0-12.35 3.857-15.113 9.456 1.06.826 4.058 3.131 5.676 4.373"
      />
      <path
        fill="#FFBD00"
        d="M20.42 33.094a10.074 10.074 0 01-.491-3.099c0-1.108.186-2.17.515-3.166a1655.31 1655.31 0 01-5.675-4.373 16.983 16.983 0 00-1.77 7.54 17 17 0 001.742 7.482l5.679-4.384z"
      />
      <path
        fill="#00AA4B"
        d="M35.467 38.536c-1.576.998-3.52 1.479-5.586 1.479-4.42 0-8.164-2.905-9.46-6.92l-5.68 4.383c2.75 5.629 8.487 9.513 15.14 9.513 4.368 0 8.177-1.57 11.003-4.244-1.708-1.328-4.385-3.411-5.417-4.211"
      />
      <path
        fill="#0086F8"
        d="M39.338 26.888H29.88v6.588h9.064c-.522 2.326-1.788 3.99-3.478 5.06 1.032.8 3.71 2.883 5.417 4.21 3.204-3.03 5.14-7.484 5.126-12.75-.003-1.077-.188-2.087-.378-3.108l-.001-.007h-6.295l.002.007"
      />
    </g>
  </svg>
)
