import { Colors } from 'common-constants/colors'

import React, { FC } from 'react'
import { SocialSvgProps } from 'components/presentational/svg/Svg.constants'

export const MailSvg: FC<SocialSvgProps> = ({
  size = 60,
  className,
  fillColor = '#FF9E02',
  fillBackgroundColor = Colors.mailRu,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    width={size}
    height={size}
    viewBox="0 0 60 60"
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill={fillBackgroundColor}
        stroke={fillBackgroundColor}
        strokeWidth="2"
        d="M9.494 9.494C14.74 4.246 21.99 1 29.998 1c8.009 0 15.26 3.246 20.507 8.494C55.754 14.742 59 21.992 59 30c0 8.009-3.246 15.259-8.494 20.507C45.258 55.755 38.007 59 29.998 59c-8.008 0-15.257-3.246-20.505-8.494C4.246 45.26 1 38.01 1 30c0-8.008 3.246-15.258 8.494-20.506z"
      />
      <path
        fill={fillColor}
        d="M30.314 21.321c2.427 0 4.708 1.072 6.38 2.751v.006c0-.806.544-1.413 1.298-1.413l.188-.002c1.18 0 1.42 1.114 1.42 1.467l.007 12.529c-.082.819.846 1.244 1.362.718 2.011-2.067 4.416-10.63-1.252-15.588-5.286-4.626-12.373-3.865-16.143-1.265-4.006 2.764-6.574 8.882-4.082 14.63 2.722 6.266 10.488 8.138 15.108 6.272 2.337-.945 3.419 2.219.988 3.252-3.667 1.562-13.885 1.403-18.656-6.862-3.225-5.581-3.052-15.404 5.497-20.491 6.542-3.892 15.167-2.815 20.369 2.616 5.435 5.678 5.119 16.31-.185 20.447-2.4 1.877-5.97.046-5.95-2.69l-.023-.894c-1.673 1.662-3.9 2.629-6.326 2.629-4.787 0-9.005-4.217-9.005-9.007 0-4.84 4.218-9.105 9.005-9.105zm6.035 8.757c-.184-3.508-2.785-5.616-5.932-5.616H30.3c-3.626 0-5.643 2.852-5.643 6.094 0 3.632 2.434 5.924 5.63 5.924 3.56 0 5.9-2.607 6.07-5.693l-.007-.709z"
      />
    </g>
  </svg>
)
