import React, { FC } from 'react'

export const ViberAppSvg: FC<{
  size?: number
  className?: string
}> = ({ size = 60, className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 60 60"
    className={className}
  >
    <g fill="none" fillRule="evenodd">
      <circle cx="30" cy="30" r="30" fill="#7360F2" fillRule="nonzero" />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M43.936 15.703c-.944-.87-4.756-3.638-13.246-3.676 0 0-10.013-.603-14.894 3.874-2.717 2.717-3.673 6.694-3.774 11.624-.1 4.93-.231 14.17 8.675 16.674h.009l-.006 3.823s-.057 1.548.962 1.863c1.233.383 1.956-.793 3.133-2.061.646-.696 1.538-1.719 2.21-2.5 6.09.513 10.775-.66 11.307-.832 1.23-.399 8.19-1.29 9.321-10.53 1.168-9.521-.565-15.545-3.697-18.26z"
      />
      <path
        fill="#7360F2"
        fillRule="nonzero"
        d="M41.955 17.626c-.797-.724-4.24-2.887-11.422-2.919 0 0-8.505-.558-12.633 3.181-2.298 2.269-3.072 5.643-3.157 9.76-.085 4.117-.525 11.983 7.006 14.075l-.033 6.352c0 .36.057.605.262.655.148.036.369-.04.557-.228 1.204-1.209 5.059-5.86 5.059-5.86 5.173.338 9.288-.683 9.73-.828 1.044-.336 6.689-.82 7.644-8.536.987-7.954-.363-13.383-3.013-15.652z"
      />
      <path
        stroke="#FFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".768"
        d="M34.185 27.31c-.093-1.883-1.05-2.873-2.87-2.967m5.334 3.793c.038-1.755-.482-3.22-1.56-4.398-1.082-1.181-2.58-1.832-4.502-1.972"
      />
      <path
        stroke="#FFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".768"
        d="M39.17 29.122c-.022-3.044-.932-5.442-2.728-7.193-1.796-1.751-4.03-2.636-6.702-2.654"
      />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M31.41 33.741s.675.057 1.039-.39l.708-.892c.342-.442 1.167-.724 1.975-.274.605.345 1.193.72 1.76 1.124.536.395 1.634 1.31 1.637 1.31.523.442.644 1.09.288 1.774l-.003.014c-.392.68-.89 1.293-1.474 1.816-.007.003-.007.007-.014.01-.508.425-1.007.666-1.497.724-.072.013-.146.017-.219.014a1.999 1.999 0 01-.637-.098l-.016-.023c-.755-.213-2.016-.747-4.116-1.905a24.4878 24.4878 0 01-3.459-2.285c-.544-.43-1.064-.89-1.556-1.38l-.052-.052-.052-.052-.053-.053-.052-.052c-.49-.492-.95-1.011-1.38-1.556a24.5355 24.5355 0 01-2.285-3.458c-1.158-2.1-1.691-3.36-1.904-4.117l-.024-.016c-.067-.206-.1-.42-.097-.637-.004-.073 0-.147.013-.219.061-.49.303-.989.725-1.498.003-.007.007-.007.01-.013a7.38 7.38 0 011.816-1.475l.015-.003c.683-.356 1.331-.235 1.772.285.004.003.918 1.1 1.31 1.637.404.568.78 1.156 1.125 1.762.45.807.168 1.634-.274 1.975l-.892.708c-.45.364-.39 1.039-.39 1.039s1.32 4.997 6.253 6.256z"
      />
    </g>
  </svg>
)
