import React, { FC } from 'react'

interface InstagramSvgProps {
  size?: number
}

export const InstagramSvg: FC<InstagramSvgProps> = ({ size = 60 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 60 60"
  >
    <g fill="none" fillRule="evenodd">
      <path
        stroke="#E5659B"
        strokeWidth="2"
        d="M29.998 59C13.983 59 1 46.018 1 30 1 13.985 13.983 1 29.998 1 46.017 1 59 13.985 59 30c0 16.018-12.983 29-29.002 29z"
      />
      <g fill="#E5659B">
        <path d="M29.67 16.004c4.45 0 4.978.017 6.736.097 1.625.074 2.508.345 3.095.574a5.165 5.165 0 0 1 1.917 1.247 5.164 5.164 0 0 1 1.247 1.916c.228.588.5 1.47.574 3.096.08 1.758.097 2.285.097 6.736 0 4.45-.017 4.978-.097 6.736-.074 1.625-.346 2.508-.574 3.095a5.164 5.164 0 0 1-1.247 1.917 5.164 5.164 0 0 1-1.917 1.247c-.587.228-1.47.5-3.095.574-1.758.08-2.285.097-6.736.097-4.452 0-4.979-.017-6.736-.097-1.626-.074-2.508-.346-3.096-.574a5.164 5.164 0 0 1-1.916-1.247 5.164 5.164 0 0 1-1.247-1.917c-.229-.587-.5-1.47-.574-3.095-.08-1.758-.097-2.285-.097-6.736s.016-4.978.097-6.736c.074-1.625.345-2.508.574-3.096a5.164 5.164 0 0 1 1.247-1.916 5.165 5.165 0 0 1 1.916-1.247c.588-.229 1.47-.5 3.096-.574 1.757-.08 2.285-.097 6.736-.097m0-3.004c-4.528 0-5.095.02-6.873.1-1.775.081-2.986.363-4.047.775a8.171 8.171 0 0 0-2.952 1.923 8.171 8.171 0 0 0-1.923 2.952c-.412 1.06-.694 2.273-.775 4.047-.08 1.778-.1 2.346-.1 6.873s.02 5.095.1 6.873c.081 1.774.363 2.986.775 4.046a8.172 8.172 0 0 0 1.923 2.953 8.171 8.171 0 0 0 2.952 1.922c1.06.412 2.272.694 4.047.775 1.778.081 2.345.1 6.873.1 4.527 0 5.095-.019 6.873-.1 1.774-.08 2.986-.363 4.046-.775a8.171 8.171 0 0 0 2.953-1.922 8.171 8.171 0 0 0 1.922-2.953c.412-1.06.694-2.272.775-4.046.081-1.778.1-2.346.1-6.873s-.019-5.095-.1-6.873c-.08-1.774-.363-2.986-.775-4.047a8.171 8.171 0 0 0-1.922-2.952 8.171 8.171 0 0 0-2.953-1.923c-1.06-.412-2.272-.694-4.046-.775-1.778-.08-2.346-.1-6.873-.1" />
        <path d="M29.67 21.11a8.56 8.56 0 1 0 0 17.12 8.56 8.56 0 0 0 0-17.12zm0 14.116a5.557 5.557 0 1 1 0-11.113 5.557 5.557 0 0 1 0 11.113zM40.568 20.771a2 2 0 1 1-4 0 2 2 0 0 1 4 0" />
      </g>
    </g>
  </svg>
)
