import { Colors } from 'common-constants/colors'
import { white } from 'common/constants'

import React, { FC } from 'react'
import { SocialSvgProps } from 'components/presentational/svg/Svg.constants'

export const YandexSvg: FC<SocialSvgProps> = ({
  size = 60,
  className,
  fillBackgroundColor = Colors.yandexButton,
  fillColor = white,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    width={size}
    height={size}
    viewBox="0 0 60 60"
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill={fillBackgroundColor}
        stroke={fillBackgroundColor}
        strokeWidth="2"
        d="M9.477 9.477C14.714 4.24 21.949 1 29.94 1c7.993 0 15.229 3.24 20.467 8.477 5.238 5.238 8.477 12.473 8.477 20.465 0 7.993-3.239 15.229-8.477 20.466-5.237 5.237-12.473 8.476-20.467 8.476-7.991 0-15.227-3.239-20.464-8.477C4.239 45.17 1 37.935 1 29.942c0-7.991 3.24-15.227 8.477-20.465z"
      />
      <path
        fill={fillColor}
        d="M35.078 13.5h-4.614c-4.53 0-8.374 3.448-8.374 10.148 0 4.012 1.863 6.974 5.18 8.438l-6.19 11.201c-.2.363 0 .647.324.647h2.873c.242 0 .404-.084.485-.284l5.623-10.998h2.021V43.65c0 .124.124.284.284.284h2.506c.245 0 .327-.122.327-.325V13.906c0-.284-.163-.406-.445-.406zm-2.672 16.558h-1.698c-2.668 0-5.258-1.947-5.258-6.82 0-5.072 2.425-7.14 4.894-7.14h2.062v13.96z"
      />
    </g>
  </svg>
)
