import styled, { css, keyframes } from 'styled-components'

const colorAnimation = keyframes`
  0% {
    background-color: silver;
  }
  50% {
    background-color: #8a8a8a;
  }
  100% {
    background-color: silver;
  }
`

const colorAnimationRule = css`
  ${colorAnimation} 4s infinite linear;
`

export const LoadingIconCss = css`
  width: 60px;
  height: 60px;
  background-color: silver;
  animation: ${colorAnimationRule};
  border-radius: 50%;
`

export const LoadingIcon = styled.div`
  ${LoadingIconCss};
`
