import {
  isLoveMailru,
  mailruPartnerIds,
  mambaPartnerIds,
} from 'common/constants'
import { mambaPartnerId } from 'components/system/third-party/vk/vk.constants'

/**
 * https://redmine.mamba.ru/issues/108173#note-15
 * @param partnerId
 */
export const findVkAppId = (partnerId: number) => {
  if (isLoveMailru(partnerId)) {
    return 5040638
  }
  return mambaPartnerId
}
