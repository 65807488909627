import React, { FC } from 'react'
import { LoadingIcon } from 'components/presentational/LoadingIcon'
import { AppleSvg } from 'components/presentational/svg/AppleSvg'
import { FacebookSvg } from 'components/presentational/svg/FacebookSvg'
import { InstagramSvg } from 'components/presentational/svg/InstagramSvg'
import { WhatsAppSvg } from 'components/presentational/svg/WhatsAppSvg'
import { ViberAppSvg } from 'components/presentational/svg/ViberAppSvg'
import { TelegramAppSvg } from 'components/presentational/svg/TelegramAppSvg'
import { YandexSvg } from 'components/presentational/svg/YandexSvg'
import { GooglePlusSvg } from 'components/presentational/svg/GooglePlusSvg'
import { MailSvg } from 'components/presentational/svg/MailSvg'
import { VkSvg } from 'components/presentational/svg/VkSvg'
import { OkSvg } from 'components/presentational/svg/OkSvg'
import { VendorSocialList } from 'common-constants/vendorSocialList'
import { VENDORS_SOCIAL_LIST_ICONS } from './SocialIcon.constants'
import { YahooSvg } from 'components/presentational/svg/YahooSvg'

interface SocialIconProps {
  name: VendorSocialList
  icon?: string
  size?: number
}

export const SocialIcon: FC<SocialIconProps> = ({ name, icon, size = 40 }) => {
  switch (name) {
    case VendorSocialList.facebook:
      return <FacebookSvg size={size} />
    case VendorSocialList.vkontakte:
    case VendorSocialList.vkConnect:
      return <VkSvg size={size} />
    case VendorSocialList.mailru:
    case VendorSocialList.mailru_oauth2:
      return <MailSvg size={size} />
    case VendorSocialList.google:
      return <GooglePlusSvg size={size} />
    case VendorSocialList.yandex:
      return <YandexSvg size={size} />
    case VendorSocialList.odnoklassniki:
      return <OkSvg size={size} />
    case VendorSocialList.apple:
      return <AppleSvg size={size} />
    case VendorSocialList.instagramm:
      return <InstagramSvg size={size} />
    case VendorSocialList.whatsapp:
      return <WhatsAppSvg size={size} />
    case VendorSocialList.viber:
      return <ViberAppSvg size={size} />
    case VendorSocialList.telegram:
      return <TelegramAppSvg size={size} />
    case VendorSocialList.yahoo:
      return <YahooSvg size={size} />
  }

  if (VENDORS_SOCIAL_LIST_ICONS[name]) {
    return (
      <img
        src={VENDORS_SOCIAL_LIST_ICONS[name]}
        width={size}
        height={size}
        style={{ display: 'block' }}
        alt={name}
      />
    )
  }

  if (icon) {
    return (
      <img
        src={icon}
        width={size}
        height={size}
        style={{ display: 'block' }}
        alt="icon"
      />
    )
  }

  return <LoadingIcon />
}
